<template>
    <div class="contact-us">
        <HeaderTitle componentName="Contact Us"/>
        <div class="map-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.073473684365!2d101.64870357570928!3d3.075051653602153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4bfedde1e371%3A0x55cbfae009b60793!2sLYC%20Jaya!5e0!3m2!1sen!2smy!4v1723437621678!5m2!1sen!2smy"
                allowfullscreen
                frameborder="0"
                style="border:0"
                loading="lazy">
            </iframe>
        </div>
        <div class="bottom-container">
            <div class="form-container" data-aos="fade-up">
                <div class="information">
                    <div class="header">
                        Drop us an email!
                    </div>
                    <div class="descriptions">
                        Excited for a fusion of fresh concepts and classic elegance?                
                        Drop us a message, and we'll get back to you shortly.
                    </div>
                    <div class="detail">
                        <div class="left-detail">
                            <i class="fa-solid fa-location-dot"></i>
                        </div>
                        <div class="right-detail">
                            <div class="detail-title">
                                Address
                            </div>
                            <div class="detail-content">
                                31-2, Jalan PJS 3/59, Sri Manja Square 2,
                                <br>
                                Taman Sri Manja, 46000 Petaling Jaya,
                                <br>
                                Selangor
                            </div>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="left-detail">
                            <i class="fa-solid fa-phone"></i>
                        </div>
                        <div class="right-detail">
                            <div class="detail-title">
                                Phone Number
                            </div>
                            <div class="detail-content">
                                1. 603 - 7783 1118
                                <br>
                                2. 6016 - 335 5880
                            </div>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="left-detail">
                            <i class="fa-solid fa-envelope"></i>
                        </div>
                        <div class="right-detail">
                            <div class="detail-title">
                                Email Address
                            </div>
                            <div class="detail-content">
                                lycj@lycjaya.com
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form">
                    <div class="row">
                        <div class="placeholder">
                            <input type="text" placeholder="Name" v-model="form.name">
                            <div class="error-message" v-show="errors.name">
                                {{errors.name}}
                            </div>
                        </div>
                        <div class="placeholder">
                            <input type="text" placeholder="Email" v-model="form.email">
                            <div class="error-message" v-show="errors.email">
                                {{errors.email}}
                            </div>
                        </div>
                        <div class="placeholder">
                            <input type="text" placeholder="Subject" v-model="form.subject">
                            <div class="error-message" v-show="errors.subject">
                                {{errors.subject}}
                            </div>
                        </div>
                        <div class="placeholder">
                            <textarea placeholder="Message" v-model="form.message"></textarea>
                            <div class="error-message" v-show="errors.message">
                                {{errors.message}}
                            </div>
                        </div>
                        <div class="submit-container">
                            <div class="submit-button" @click="submit" :class="{ 'loading': loading }">
                                Send
                            </div>
                            <div :class="{'success-message': success, 'fail-message': !success}" v-show="submittedMessage">
                                {{submittedMessage}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderTitle from './HeaderTitle.vue';

export default {
    components: {
        HeaderTitle
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            errors: {
                name: null,
                email: null,
                subject: null,
                message: null,
            },
            loading: false,
            success: false,
            submittedMessage: ''
        }
    },
    methods: {
        validateEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return re.test(email)
        },
        validateForm() {
            let isValid = true

            if (!this.form.name) {
                this.errors.name = "This field is required."
                isValid = false;
            } 
            else {
                this.errors.name = null;
            }

            if (!this.form.email) {
                this.errors.email = "This field is required."
                isValid = false;
            } 
            else if (!this.validateEmail(this.form.email)) {
                this.errors.email = "Please enter a valid email."
                isValid = false;
            }
            else {
                this.errors.email = null;
            }

            if (!this.form.subject) {
                this.errors.subject = 'This field is required.'
                isValid = false
            } else {
                this.errors.subject = null
            }

            if (!this.form.message) {
                this.errors.message = 'This field is required.'
                isValid = false
            } else {
                this.errors.message = null
            }
            
            return isValid;
        },
        async submit() {
            this.submittedMessage = false;
            if (this.validateForm()) {
                this.loading = true;
                
                const apiUrl = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : "http://localhost:8888/.netlify/functions/sendEmail"
                try {
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(this.form),
                    });

                    const data = await response.json();

                    if (response.ok) {
                        this.submittedMessage = "Message sent! Thank you for reaching out to us."
                        this.success = true;
                        console.log(data.message);
                    } else {
                        this.submittedMessage = "Failed to send email. Please try again."
                        this.isSuccess = false;
                        console.log(data.message);
                    }
                }
                catch (error) {
                    console.log("Error:", error);
                    this.submittedMessage = "Failed to send email. Please try again."
                    this.isSuccess = false;
                }
                finally {
                    this.loading = false;
                }

                this.form = {
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                }
            }
        }
    }
}
</script>

<style scoped>
.contact-us {
    background-color: rgb(246,249,252);
}

.map-container {
    width: 100%;
    height: 600px;
}

.map-container iframe {
    width: 100%;
    height: 100%;
}

.bottom-container {
    position: relative;
    width: 100%;
    padding-top: 275px;
    padding-bottom: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    position: absolute;
    top: -220px;
    width: 1116px;
    background: #FFFFFF;
    display: flex;
}

.information {
    width: 50%;
    padding: 50px 25px;
    text-align: left;
}

.form {
    width: 50%;
    padding: 50px 25px;
}

.header {
    font-size: 43px;
    font-weight: bold;
    margin-bottom: 25px;
}

.descriptions {
    font-size: 16px;
    line-height: 1.5;
    word-spacing: 3px;
    letter-spacing: 0.75px;
    margin-bottom: 30px;
}

.detail {
    display: flex;
    margin-bottom: 25px;
}

.left-detail {
    width: 50px;
}

.left-detail .fa-solid {
    font-size: 25px;
    color: #00bfff;
}

.detail-title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: .75px;
    line-height: 25px;
    margin-bottom: 12px;
}

.detail-content {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .75px;
    line-height: 24px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.placeholder {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 30px;
    letter-spacing: 0.8px;
    display: flex;
    flex-direction: column;
    /* align-content: center; */
}

.placeholder input {
    width: 85%;
    padding: 16px;
    font-size: 17px;
    border: 1px solid #00bfff;
    letter-spacing: 0.8px;
}

.placeholder input:focus {
    outline: none;
    border: 1px solid black;
}

.placeholder textarea {
    height: 100px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 85%;
    padding: 16px;
    font-size: 17px;
    resize: none;
    border: 1px solid #00bfff;
    letter-spacing: 0.8px;
}

.placeholder textarea:focus {
    outline: none;
    border: 1px solid black;
}

::placeholder {
    color: #B4B4B4;
}

.error-message {
    display: flex;
    width: 85%;
    padding-top: 8px;
    text-align: left;
    font-size: 15px;
    color: #D75A5A;
}

.submit-container {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
}

.submit-button {
    width: 85%;
    padding: 10px 16px;
    font-size: 17px;
    background-color: #00bfff;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 24px;
    transition: background-color 0.2s ease-in, color 0.2s ease-in, border 0.2s ease-in;
    border: 1px solid #00bfff;
}

.submit-button:hover {
    cursor: pointer;
    color: #00bfff;
    background: white;
    border: 1px solid #00bfff;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.submit-button.loading {
    background-color: #ccc;
    cursor: not-allowed;
    border: 0px;
    color: white;
}

.success-message {
    text-align: left;
    margin-top: 15px;
    width: 85%;
    letter-spacing: 0.8px;
    color: #52ac57;
}

.fail-message {
    text-align: left;
    margin-top: 15px;
    width: 85%;
    letter-spacing: 0.8px;
    color: #D75A5A;
}

@media (max-width: 1130px) {
    .form-container {
        width: 940px;
    }
}

@media (max-width: 985px) {
    .bottom-container {
        padding-top: 500px;
        padding-bottom: 500px;
    }

    .form-container {
        flex-direction: column;
        width: 80%;
        padding: 50px 25px;
    }

    .information {
        width: 100%;
        padding: 0px;
        margin-bottom: 20px;
    }

    .form {
        width: 100%;
        padding: 0px;
    }
}

@media (max-width: 985px) {
    .bottom-container {
        padding-top: 565px;
        padding-bottom: 565px;
    }
}

</style>