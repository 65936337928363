<template>
    <div class="main-footer">
        <div class="top-footer" >
            <div class="views" v-if="!isLoading">
                <div class="views-title">
                    <i class="fa-solid fa-chart-simple"></i>
                    Visit counts
                </div>
                <div class="view">
                    Total views: {{ totalViews }}
                </div>
                <div class="view">
                    Today views: {{ todayViews }}
                </div>
            </div>
            <div class="loading-views" v-else>
                Loading views..
            </div>
        </div>
        <div class="bot-footer">
            <div class="bot-container">
                <div class="contact-container">
                    <div class="contact-title">Contact Us</div>
                    <div class="contact-content">
                        <div class="phone-number">
                            <div class="phone-icon">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </div>
                            <div class="phone-number-content">
                                603-77831118
                            </div>
                        </div>
                        <div class="address">
                            <div class="address-icon">
                                <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
                            </div>
                            <div class="address-content">
                                31-2, Jalan PJS 3/59, Sri Manja Square 2,
                                <br>
                                Taman Sri Manja, 46000 Petaling Jaya,
                                <br>
                                Selangor
                            </div>
                        </div>
                        <div class="email">
                            <div class="email-icon">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div class="email-content">
                                lycj@lycjaya.com
                            </div>
                        </div>
                        <div class="link">
                            <div class="link-icon">
                                <i class="fa-solid fa-earth-americas"></i>
                            </div>
                            <div class="link-content">
                                lycjaya.com
                            </div>
                        </div>
                    </div>
                </div>
                <div class="social-media-container">
                </div>
                <div class="opening-hours-container">
                    <div class="opening-hours-title">Opening Hours</div>
                    <div class="opening-hours-content">
                        <div :class="currentDay == 1 ? 'active' : 'normal-day'">
                            <div class="day">Monday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                        <div :class="currentDay == 2 ? 'active' : 'normal-day'">
                            <div class="day">Tuesday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                        <div :class="currentDay == 3 ? 'active' : 'normal-day'">
                            <div class="day">Wednesday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                        <div :class="currentDay == 4 ? 'active' : 'normal-day'">
                            <div class="day">Thursday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                        <div :class="currentDay == 5 ? 'active' : 'normal-day'">
                            <div class="day">Friday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                        <div :class="currentDay == 6 ? 'active' : 'normal-day'">
                            <div class="day">Saturday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                        <div :class="currentDay == 7 ? 'active' : 'normal-day'">
                            <div class="day">Sunday</div>
                            <div class="time">09:00 - 19:00</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
<script>
export default {
    async mounted() {
        var currentDay = new Date()
        this.currentDay = currentDay.getDay()

        const apiUrl = process.env.VUE_APP_API2_URL ? process.env.VUE_APP_API2_URL : "http://localhost:8888/.netlify/functions/getPageViews"
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            this.totalViews = data.totalPageViews.rows[0].metricValues[0].value ? data.totalPageViews.rows[0].metricValues[0].value : 0;
            this.todayViews = data.pageViewsToday.rows[0].metricValues[0].value ? data.pageViewsToday.rows[0].metricValues[0].value : 0;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            this.isLoading = false;
        }
    },
    data() {
    return {
        currentDay: 0,
        totalViews: 0,
        todayViews: 0,
        isLoading: true
    }
  }
}
</script>
  
<style scoped>
.main-footer {
    position: relative;
    bottom: 0px;
    width: 100%;
    height: auto;
}

.top-footer {
    height: 132px;
    width: 100%;
    background-color: rgb(48,49,51);
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
}

.loading-views {
    height: 100%;
    width: 100%;;
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: 5px;
    font-size: 40px;
    color: rgb(221, 221, 221);
}

.views {
    text-align: left;
    font-size: 16px;
    width: 1085px;
    margin-left: auto;
    margin-right: auto;
}

.views-title {
    font-size: 18px;
    margin-bottom: 45px;
    text-transform: uppercase;
    font-weight: bold;
}

.views-title i {
    margin-right: 7px;
}

.view {
    margin-bottom: 30px;
}

.view:last-child {
    margin-bottom: 0px;
}

.bot-footer {
    position: relative;
    width: 100%;
    background-color: rgb(40,41,43);
    padding-top: 30px;
    padding-bottom: 30px;
}

.bot-container {
    position: relative;
    width: 1085px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    color: white;
}

.contact-container {
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.contact-title {
    text-align: left;
    height: 30px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
}

.contact-content {
    margin-top: 25px;
    height: 250px;
    width: 100%;
    font-size: 15px;
}

.phone-number {
    display: flex;
}

.phone-number i{
    font-size: 13px;
}

.phone-icon {
    width: 13px;
}

.phone-number-content {
    position: relative;
    left: 15px;
}

.address {
    display: flex;
    position: relative;
    height: 90px;
    top: 25px;
}

.address i {
    font-size: 13px;
}

.address-icon {
    width: 13px;
}

.address-content {
    position: relative;
    left: 15px;
    text-align: left;
    line-height: 1.5;
}

.email {
    display: flex;
    position: relative;
    height: 50px;
    top: 25px;
}

.email i {
    font-size: 13px;
}

.email-icon {
    width: 13px;
}

.email-content {
    position: relative;
    left: 15px;
}

.link {
    display: flex;
    position: relative;
    height: 50px;
    top: 25px;
}

.link i {
    font-size: 13px;
}

.link-icon {
    width: 13px;
}

.link-content { 
    position: relative;
    left: 15px;
}

.social-media-container {
    height: 100%;
    width: 200px;
    margin-left: 90px;
    margin-right: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.opening-hours-container {
    width: 355px;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
}

.opening-hours-title {
    text-align: left;
    height: 30px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
}

.opening-hours-content {
    margin-top: 25px;
    height: 250px;
    font-size: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.normal-day {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.active {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: #00bfff;
    margin-bottom: 8px;
}

@media (max-width: 1130px) {
    .bot-container {
        width: 940px;
    }

    .views {
        width: 940px;
    }
}

@media (max-width: 985px) {
    .bot-container {
        width: 90%;
        flex-direction: column;
    }
    
    .contact-container {
        width: 100%;
    }

    .opening-hours-container {
        width: 100%;
    }

    .views {
        width: 90%;
    }
}

</style>