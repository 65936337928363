import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueGtag from 'vue-gtag';
import AOS from 'aos';
import 'aos/dist/aos.css';

const app = createApp(App);


app.use(VueGtag, {
        config: {
            id: 'G-WBV8YB5JMN' 
        }
    }, router);

// Initialize AOS when the app is mounted
app.mixin({
    mounted() {
        AOS.init({
            duration: 1000, // Duration of animations in ms
            easing: 'ease-out', // Easing function for animations
            once: true, // Whether animation should happen only once
        });
    }
});

app.use(router);

app.mount('#app');
