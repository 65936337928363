<template>
    <div>
        <div class="background-image">
            <div class="content">
                <div class="text-1" data-aos="fade-right">
                    LYC Jaya Sdn Bhd
                </div>
                <div class="text-2" data-aos="fade-right">
                    Join us in creating your dream space with unmatched expertise and client-focused solutions.
                </div>
                <div class="more-information" data-aos="fade-up">
                    <router-link to="/about" class="information-button">
                        More information
                    </router-link>
                </div>
            </div>
        </div>
        <div class="company-stats">
            <div class="stats-container">
                <div class="stats-img" data-aos="fade-right">
                    <img src="../assets/globe1.png" alt="">
                </div>
                <div class="right-stats">
                    <div class="stats-text" data-aos="fade-left">
                        Join the clients who trust us to turn their ideas into reality with ensured satisfaction.
                    </div>
                    <div class="stats-contents">
                        <div class="stats" data-aos="zoom-in">
                            <div class="stats-value">
                                500+
                            </div>
                            <div class="stats-category">
                                satisfied clients
                            </div>
                        </div>
                        <div class="stats" data-aos="zoom-in">
                            <div class="stats-value">
                                30+
                            </div>
                            <div class="stats-category">
                                years of  industrial expertise
                            </div>
                        </div>
                        <div class="stats" data-aos="zoom-in">
                            <div class="stats-value">
                                453
                            </div>
                            <div class="stats-category">
                                projects completed
                            </div>
                        </div>
                        <div class="stats" data-aos="zoom-in">
                            <div class="stats-value">
                                $444 B
                            </div>
                            <div class="stats-category">
                                global economic activity
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="background-image">
            <div class="contact-us-background">
                <div class="text-1" data-aos="fade-down">
                    Get in Touch
                </div>
                <div class="text-2" data-aos="fade-down">
                    We're here to help you with any questions or concerns you may have. 
                        Reach out to us and let's start a conversation about your needs.
                </div>
                <div class="more-information" data-aos="fade-up">
                    <router-link to="/contact" class="information-button">
                        Submit an enquiry
                    </router-link>
                </div>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            image: require('@/assets/picture1.jpg')
        }
    },
    methods: {
    preloadImage(url) {
        return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = resolve;
                img.onerror = reject;
            });
        }
    },
    mounted() {
    this.preloadImage(this.image)
        .then(() => {
            console.log('Image preloaded successfully');
        })
        .catch((error) => {
            console.error('Image failed to preload', error);
        });
    }
}
</script>

<style scoped>
.background-image {
    position: relative;
    width: 100%;
    height: 850px;
    /* padding-top: 20px;
    padding-bottom: 5px; */
}

.content {
    min-height: 100%;
    filter: brightness(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: left;
    /* word-spacing: 8px; */
    font-family: "Ubuntu", Sans-serif;
}

.content::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    /* background-image: linear-gradient(to bottom, rgba(33, 39, 69, 0.8), rgba(33, 39, 69, 0.5)), url("../assets/picture1.jpg"); */
    background-image: linear-gradient(to bottom, rgba(40, 41, 43, 0.4), rgba(40, 41, 43, 0.2)), url('../assets/CompanyImage.jpg');
    /* background-image: url("../assets/product1.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    filter: brightness(80%);
    opacity: 0.9;
}

.contact-us-background {
    min-height: 100%;
    filter: brightness(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: left;
    /* word-spacing: 8px; */
    font-family: "Ubuntu", Sans-serif;
}

.contact-us-background::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    /* background-image: linear-gradient(to bottom, rgba(33, 39, 69, 0.8), rgba(33, 39, 69, 0.5)), url("../assets/picture1.jpg"); */
    background-image: linear-gradient(to bottom, rgba(40, 41, 43, 0.3), rgba(40, 41, 43, 0.1)), url('../assets/contactus.jpg');
    /* background-image: url("../assets/product1.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    filter: brightness(80%);
    opacity: 0.9;
}

.contact-heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.contact-description {
    font-size: 20px;
    margin-bottom: 20px;
}

.contact-button-link {
    font-size: 18px;
    font-weight: bold;
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
    border: 2px solid white;
    background: transparent;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.contact-button-link:hover {
    background-color: white;
    color: black;
}

@supports (-webkit-overflow-scrolling: touch) {
    .content::after {
        background-attachment: scroll;
    }

    .contact-us-background:after {
        background-attachment: scroll;
    }
}

.text-1 {
    width: 80%;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
}

.text-2 {
    margin-top: 30px;
    width: 80%;
    font-size: 22px;
}

.more-information {
    margin-top: 150px;
    width: 80%
}

.information-button {
    font-size: 17px;
    font-weight: bold;
    color: black;
    width: fit-content;
    padding: 18px 24px;
    border-radius: 30px;
    border: 2px solid white;
    background: white;
    letter-spacing: 0.6px;
    text-decoration: none; 
}

.information-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid white;
    color: white;
    transition: 0.2s ease-in color, 0.2s ease background-color;
}

.company-stats {
    /* height: 850px; */
    width: 100%;
    background: #F6F9FC;
}

.stats-container {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5%;
    max-width: 1400px;
}

.stats-img{
    padding: 100px 40px;
    display: flex;
    justify-content: center;
    width: 50%;
}

.stats-img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the image scales nicely */
    height: auto;
    width: auto;
}

.right-stats {
    padding: 100px 40px;
    width: 50%;
}

.stats-text {
    text-align: left;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0.8px;
    color: rgb(44, 158, 211)}

.stats-contents {
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
}

.stats {
    color: black;
    width: 40%;
    font-size: 40px;
    letter-spacing: 0.8px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 45px;
    text-align: left;
}

.stats::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px; /* Adjust the width of the line */
    background: linear-gradient(to bottom, #01bae2, #c6eaff);
    border-radius: .125rem;
}

.stats-value {
    margin-bottom: 15px;
}

.stats-category {
    font-size: 18px;
    color: #52525B;
}

@media (max-width: 1130px) {
    .stats-container {
        flex-direction: column
    }

    .stats-img{
        padding: 15% 10%;
        display: flex;
        justify-content: center;
        width: 80%;
    }

    .right-stats {
        padding: 15% 10%;
        width: 80%;
    }

    .stats {
        width: 90%;
    }
}
</style>