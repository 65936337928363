<template>
    <div class="main-container" v-show="title != ''">
        <div class="content">
            <div class="title">
                {{title}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        componentName: {
            type: String,
            required: false
        }
    },
    computed: {
        title() {
            return `${this.componentName}`;
        }
  } 
}
</script>

<style scoped>
.main-container {
    position: relative;
    width: 100%;
    height: 315px;
    /* padding-top: 20px;
    padding-bottom: 5px; */
}

.content {
    height: 100%;
    filter: brightness(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /* word-spacing: 8px; */
    font-family: "Ubuntu", Sans-serif;
    width: 100%;
}

.content::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    /* background-image: linear-gradient(to bottom, rgba(33, 39, 69, 0.8), rgba(33, 39, 69, 0.5)), url("../assets/picture1.jpg"); */
    background-image: linear-gradient(to bottom, rgba(40, 41, 43, 0.8), rgba(40, 41, 43, 0.5)), url('../assets/picture1.jpg');
    /* background-image: url("../assets/product1.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    filter: brightness(80%);
    opacity: 0.9;
}

@supports (-webkit-overflow-scrolling: touch) {
  .content::after {
    background-attachment: scroll;
  }
}

.title {
    width: 80%;
    font-size: 50px;
    font-weight: 600;
    word-spacing: 15px;
    text-transform: uppercase;
}
</style>