<template>
    <div class="main">
        <HeaderTitle componentName="Past Projects"/>
        <div class="hospital">
            <div class="left-section">
                <h2>Subang Jaya Medical Centre</h2>
                <div class="buttons">
                    <button :class="{ 'active-button': section === 'nuclear'}" @click="changeSection('nuclear')">Nuclear Medicine</button>
                    <button :class="{ 'active-button': section === 'hemodialysis'}" @click="changeSection('hemodialysis')">Hemodialysis Centre</button>
                    <button :class="{ 'active-button': section === 'rehab'}" @click="changeSection('rehab')">Rehab Centre</button>
                    <button :class="{ 'active-button': section === 'daycare'}" @click="changeSection('daycare')">Daycare</button>
                </div>
            </div>
            <div class="right-section">
                <div class="carousel">
                    <Carousel :autoplay="5000" :transition="400" :pauseAutoplayOnHover="true" :wrap-around="true" v-model="currentSlide">
                        <Slide v-for="(image, index) in currentImages" :key="index">
                            <div class="carousel_item">
                                <img :src="image" alt="'Image' + 'index'" />
                            </div>
                        </Slide>   
                        <template #addons>
                            <Navigation />
                            <Pagination />
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
        <div class="">
            
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import HeaderTitle from './HeaderTitle.vue';

const nuclearContext = require.context('../assets/hopsital/nuclear', false, /\.(png|jpe?g|svg)$/);
const hemodialysisContext = require.context('../assets/hopsital/hemodialysis', false, /\.(png|jpe?g|svg)$/);
const rehabContext = require.context('../assets/hopsital/rehab', false, /\.(png|jpe?g|svg)$/);
const daycareContext = require.context('../assets/hopsital/daycare', false, /\.(png|jpe?g|svg)$/);
const hemodialysisImages = hemodialysisContext.keys().map(hemodialysisContext);
const nuclearImages = nuclearContext.keys().map(nuclearContext);
const rehabImages = rehabContext.keys().map(rehabContext);
const daycareImages = daycareContext.keys().map(daycareContext);

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        HeaderTitle
    },
    data() {
        return {
            section: "nuclear",
            images: {
                nuclear: nuclearImages,
                hemodialysis: hemodialysisImages,
                rehab: rehabImages,
                daycare: daycareImages
            },
            currentImages: nuclearImages,
            currentSlide: 0
        };
    },
    methods: {
        changeSection(section) {
            if (this.images[section]) {
                this.currentImages = this.images[section]; 
                this.section = section;     
                this.currentSlide = 0;
            }
        }
    },
};
</script>

<style scoped>
/* .main {
    margin: auto;
    width: 70%;
} */

.main {
    width: 100%;
}

.hospital {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1400px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.left-section {
  width: 30%;
}

.right-section {
  width: 60%;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 90px;
}


button {
    display: block;
    margin-bottom: 15px; /* Reduce space between buttons */
    padding: 12px 24px; /* Slightly reduced padding for a more minimal look */
    background-color: transparent; /* Transparent background */
    color: #00bfff; /* Text color matches the nav bar hover color */
    border: 2px solid #00bfff; /* Thin border to outline the button */
    border-radius: 20px; /* Slightly rounded corners for a subtle effect */
    cursor: pointer;
    font-size: 14px;
    font-weight: normal; /* Normal font weight for a more understated look */
    text-transform: uppercase; /* Uppercase text for consistency */
    transition: all 0.2s ease; /* Smooth transition for hover effect */
    outline: none; /* Remove default focus outline */
}

button:hover {
    color: #ffffff; /* Text color changes on hover */
    background-color: #00bfff; /* Background color matches the hover color */
    border-color: #00bfff; /* Border color matches the background */
}



.active-button {
    color: #ffffff; /* Text color when active */
    background-color: #00bfff; /* Background color when active */
    border-color: #00bfff; /* Border color when active */
}

/* button:hover {
  background-color: #4a4b4d;
} */

.carousel {
    width: 100%;
}

.carousel_item {
    min-height: 200px;
    width: 100%;
    /* background-color: green; */
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 0px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
}

img {
    width: 80%;
}
</style>