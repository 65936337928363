<template>
    <div class="about-us">
        <HeaderTitle componentName="About Us"/>
        <div class="about-container">
            <div class="introduction" data-aos="fade-right">
                <div class="introduction-title">
                    Introduction
                </div>
                <div class="introduction-content">
                    LYC JAYA SDN BHD is a leading construction and renovation company known for its exceptional services. 
                    With a strong reputation for delivering projects on time and within budget, we specialize in industrial, 
                    hospital, office, and residential renovations. Our end-to-end client experience ensures seamless communication, 
                    precise budgeting, efficient staffing, organized on-site operations, and superior craftsmanship. We prioritize 
                    quality and client satisfaction, employing a skilled team dedicated to achieving the highest standards. By 
                    choosing LYC JAYA, clients can expect excellence, professionalism, and remarkable results in their projects.
                </div>
            </div>
            <div class="vision-and-mission">
                <div class="vision">
                    <div class="vision-img" data-aos="zoom-in">
                        <img src="../assets/innovative.png" alt="">
                    </div>
                    <div class="title" data-aos="zoom-in-right">
                        Our Vision
                    </div>
                    <div class="content" data-aos="zoom-in-right">
                        To be a leader in our industry, known for our unwavering commitment to excellence, customer satisfaction, and innovation.
                        We strive to continuously expand our reach and influence, becoming the go-to choice for individuals and businesses seeking top-quality
                        products and services.
                    </div>
                </div>
                <div class="line"></div>
                <div class="vision">
                    <div class="vision-img">
                        <img src="../assets/leadership.png" alt="" data-aos="zoom-in">
                    </div>
                    <div class="title" data-aos="zoom-in-left">
                        Our Mission
                    </div>
                    <div class="content" data-aos="zoom-in-left">
                        To deliver high-quality, innovative products and services that exceed market needs, foster mutually beneficial
                        relationships, and empower our customers to achieve their aspirations.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderTitle from './HeaderTitle.vue';

export default {
    props: {
        loader: {
            type: String,
            required: true
        }
    },
    components: {
        HeaderTitle
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.about-container {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 75px;
    padding-bottom: 75px;
    flex-direction: column;
    /* background-image: url('../assets/aboutus.jpg');
    background-size: cover; */
    /* background: rgb(215, 234, 255); */
}

.introduction {
    max-width: 1116px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 20px;
}

.introduction-title {
    text-align: left;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 30px;
}

.introduction-content {
    text-align: justify;
    font-size: 20px;
    word-spacing: 3px;
    letter-spacing: 0.8px;
    line-height: 1.5;
}

.vision-and-mission {
    max-width: 1116px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    gap: 5%;
}

.line {
    border-left: 1px solid #c0c0c0; /* Line thickness and color */
    /* width: 1px; */
    margin-left: 10px; /* Optional: add some spacing */
}

.vision {
    width: 30%;
}

.vision-img {
    display: flex;
    justify-content: center;
    object-fit: contain;
    padding: 40px;
}

.vision-img img {
    max-width: 100%;
    height: auto;
}

.title {
    margin-top: 45px;
    text-align: left;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.content {
    text-align: justify;
    word-spacing: 1px;
    letter-spacing: 0.8px;
    line-height: 1.5;
    font-size: 17px;
}

@media (max-width: 1200px) {
    .introduction {
        max-width: 940px;
        padding: 30px;
    }

    .vision-and-mission {
        max-width: 940px;
        gap: 2%;
    }

    .vision {
        width: 40%;
    }

    .line {
        display: none;
    }
}

@media (max-width: 985px) {
    .vision-and-mission {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .vision {
        width: 90%;
    }
}
</style>