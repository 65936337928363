<template>
    <div class="services">
        <HeaderTitle componentName="Our Services"/>
        <div class="top-container">
            <section class="top-section">
                <div class="text-content" data-aos="fade-right">
                    <div class="services-title">Our Services</div>
                    <p>
                        LYC Jaya Sdn Bhd excels in a wide range of construction and renovation projects. Our portfolio includes successful
                        ventures in diverse sectors such as hospitals, large offices, commercial factories, plantations, and various industries.
                    </p>
                    <p>
                        With a commintment to meeting your construction needs, LYC Jaya offers a comprenhensive array of services that guide you 
                        from initial collaboration meetings to the final ribbon-cutting ceremony and beyond. We firmly believe that every projects 
                        deserves the same attention to detail as if we were personally moving in ourselves.
                    </p>
                    <p>
                        While our expertise lies in design-build projects, our proficiency extends to encompass all aspects of construction serves. 
                        Discover our exceptional services below, tailored to help you achieve your unique objectives.
                    </p>
                </div>
                <div class="image-content" data-aos="fade-left">
                    <img src="../assets/image2.png" alt="About Us">
                </div>
            </section>
        </div>
        <div class="line">
        </div>
        <div class="bot-container">
            <section class="bot-section">
                <div class="flexbox">
                    <div class="box" data-aos="fade-up">
                        <img src="../assets/safety.png" alt="">
                        <div class="box-title">
                            Safety Inspection
                        </div>
                        <div class="box-content">
                            LYC Jaya ensures utmost safety and prevention at every construction site, offering comprehensive safety inspections, resources, training, and enforcement. 
                            Your safety is our top priority.
                        </div>
                    </div>
                    <div class="box" data-aos="fade-up">
                        <img src="../assets/preconstruction.png" alt="">
                        <div class="box-title">
                            Pre-construction
                        </div>
                        <div class="box-content">
                            At LYC Jaya, we excel at actively managing budget development, providing valuable input on methods and alternatives, and closely monitoring material availability
                             and cost compatibility.
                        </div>
                    </div>
                </div>
                <div class="flexbox">
                    <div class="box" data-aos="fade-up">
                        <img src="../assets/remodeling.png" alt="">
                        <div class="box-title">
                            Remodeling
                        </div>
                        <div class="box-content">
                            Transform your property with our expert remodeling services. At LYC Jaya, we revitalize existing spaces, improve lighting, and enhance flow through innovative 
                            design and construction alternatives. Elevate your property's appeal with our transformative solutions.
                        </div>
                    </div>
                    <div class="box" data-aos="fade-up">
                        <img src="../assets/consultation.png" alt="">
                        <div class="box-title">
                            Consultation
                        </div>
                        <div class="box-content">
                            Our expertise and industry knowledge bring significant value to projects at every stage, from proposal and pre-construction to design development and construction
                             stages.
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import HeaderTitle from './HeaderTitle.vue';

export default {
    components: {
        HeaderTitle
    }
}
</script>

<style scoped>
.servces {
    background-color: rgb(246,249,252);
}

.top-container {
    width: 100%;
}

.top-section {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    color: #213343;
    padding-top: 70px;
    padding-bottom: 70px;
    max-width: 1400px;
}

.services-title {
    text-align: left;
    font-size: 50px;
    font-weight: 550;
}

.text-content {
    padding-left: 0px;
    flex: 1;
    text-align: justify;
    max-width: 600px;
    word-spacing: 2.8px;
    line-height: 1.75;
}

.line {
    display: flex;
    max-width: 1400px;
    border-top: 2px solid #213343;
    margin: auto;
    height: 50px;
}

.bot-container {
    width: 100%;
}

.bot-section {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
    justify-content: center;
}

.flexbox {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 500px;
    margin-bottom: 20px;
}

.box {
    width: 40%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.box img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 4%;
}

.box-title {
    font-weight: bold;
    margin-bottom: 3%;
}

.box-content {
    text-align: justify;
    line-height: 1.3;
}
</style>