import { createWebHistory, createRouter } from 'vue-router'

import LandingPage from './components/LandingPage.vue'
import AboutUs from './components/AboutUs.vue'
import ServicesPage from './components/ServicesPage.vue'
import PastProjects from './components/PastProjects.vue'
import ContactUs from './components/ContactUs.vue'

const routes = [
    { 
        path: '/', 
        name: '',
        component: LandingPage
    },
    { 
        path: '/about', 
        name: 'About Us',
        component: AboutUs 
    },
    { 
        path: '/services',
        name: 'Our Services',
        component: ServicesPage 
    },
    { 
        path: '/projects', 
        name: 'Past Projects',
        component: PastProjects 
    },
    { 
        path: '/contact', 
        name: 'Contact Us',
        component: ContactUs 
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0, behavior: 'smooth' }; // Scroll to the top smoothly
        }
    }
})

export default router