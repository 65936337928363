<template>
  <div class="wrapper">
    <div class="main-container">
        <router-link to="/">
            <div class="logo">
                <img src="../assets/logo.png" alt="">
            </div>
        </router-link>
        <div class="contents">
            <div class="links"><router-link to="/" active-class="active">Home</router-link></div>
            <div class="links"><router-link to="/about" active-class="active">About Us</router-link></div>
            <div class="links"><router-link to="/services" active-class="active">Services</router-link></div>
            <div class="links"><router-link to="/projects" active-class="active">Projects</router-link></div>
            <div class="links"><router-link to="/contact" active-class="active">Contact Us</router-link></div>
        </div>
        <div class="hamburger" @click="toggleMenu">
            <i class="fa-solid fa-bars"></i>
        </div>
    </div>
    <Transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div class="responsive-navbar" v-show="isMenuOpen">
            <router-link to="/" active-class="active" @click="closeMenu">
                <div class="responsive-links">Home</div>
            </router-link>
            <router-link to="/about" active-class="active" @click="closeMenu">
                <div class="responsive-links">About Us</div>
            </router-link>
            <router-link to="/services" active-class="active" @click="closeMenu">
                <div class="responsive-links">Services</div>
            </router-link>
            <router-link to="/projects" active-class="active" @click="closeMenu">
                <div class="responsive-links">Projects</div>
            </router-link>
            <router-link to="/contact" active-class="active" @click="closeMenu">
                <div class="responsive-links">Contact Us</div>
            </router-link>
        </div>
    </Transition>
  </div>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu() {
            this.isMenuOpen = false;
        },
        beforeEnter(el) {
            el.style.maxHeight = '0';
            el.style.opacity = '0';
        },
        enter(el) {
            el.style.transition = 'max-height 0.5s ease, opacity 0.5s ease';
            el.style.maxHeight = `${el.scrollHeight}px`;
            el.style.opacity = '1';
        },
        leave(el) {
            el.style.transition = 'max-height 0.5s ease, opacity 0.5s ease';
            el.style.maxHeight = '0';
            el.style.opacity = '0';
        }
    }
}
</script>

<style scoped>
.wrapper {
    width: 100%;
    height: 120px;
    /* background: #28292b; */
    background: white;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    font-family: Poppins, sans-serif;
    z-index: 3;
    position: sticky;
    top: 0px;
}

.main-container {
    margin: auto;
    max-width: 1470px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.responsive-navbar {
    display: none;
}

.logo {
    height: 100%;
    width: auto;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo:hover {
    cursor: pointer;
}

.contents {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    font-size: 18px;
}

.links {
    text-decoration: none;
    padding: 35px;
}

a { 
    text-decoration: none; 
    color: #212121;
    transition: 0.2s ease-in color;
}

a:hover {
    color: #00bfff;
    cursor: pointer;
}

a.active {
    color: #00bfff;
}

/* Hamburger Menu for Small Screens */
.hamburger {
    display: none;
    font-size: 30px;
    cursor: pointer;
}

.responsive-navbar {
    display: none;
    flex-direction: column;
    background: white;
    position: absolute;
    top: 120px;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.responsive-links {
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.responsive-links:last-child {
    border-bottom: none;
}

.responsive-navbar a {
    border-bottom: 1px solid #ddd;
    transition: 0.2s ease-in background-color, 0.2s ease-in color;
}

/* .responsive-navbar:last-child {
    border-bottom: none;
} */

.responsive-navbar a:hover {
    background-color: #00bfff;
    color: white;
}

@media (max-width: 1130px) {
    .contents {
        display: none;
    }

    .hamburger {
        display: flex;
        padding-left: 40px;
        padding-right: 40px;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in color;
    }

    .hamburger:hover {
        color: #00bfff;
        cursor: pointer;
    }

    .responsive-navbar {
        display: flex;
    }
}
</style>